<div class="filter-container d-flex flex-column spacing-12">
  <div class="filter-container-row d-flex spacing-12">
    <!-- Search Input -->
    <div
      app-search
      [active]="false"
      class="w-100"
      [SearchBtn]="false"
      [disabled]="false"
      [searchString]="search || ''"
      (onSearch)="onTextSearch($event)"
      size="large"></div>

    <!-- Filter Selection -->
    <app-secondary-btns
      [active]="false"
      label="Filters"
      size="medium"
      IconValue=""
      IconType="convert"
      Type="leftIcon"
      class="position-relative"
      (click)="toggleSelection()"
      type="button"
      cdkOverlayOrigin
      #trigger="cdkOverlayOrigin">
    </app-secondary-btns>
  </div>

  <!-- Applied filters  -->

  <app-filter-preview
    (onRemove)="onRecordRemove($event)"
    (onChange)="onRecordsChange($event)"
    (clearAllFilter)="clearFilter()"
    [records]="records"></app-filter-preview>

  <!--  {{ query | json }}-->
</div>

<!-- Filter Dropdown Selection  -->
<ng-template
  class="filter-overlay"
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOffsetY]="12"
  [cdkConnectedOverlayOpen]="selectionOpen"
  (overlayOutsideClick)="toggleSelection(false)">
  <div class="filter-selection">
    <div class="filter-selection-header">
      <span class="h3-bold">Filters</span>
      <app-link-btn
        class="text-nowrap"
        label="Clear all"
        [active]="false"
        size="medium"
        IconType="none"
        [disabled]="false"
        (custom_click)="clearFilter()"></app-link-btn>
    </div>
    <div
      class="w-100"
      app-search
      [active]="false"
      placeholder="Search filters..."
      (onSearch)="onFilterSearch($event)"
      [SearchBtn]="false"
      [disabled]="false"
      size="medium"></div>
    <div class="filter-selection-group" *ngFor="let group of groups">
      <div class="d-flex align-items-center spacing-12">
        <app-icon-container
          [Type]="'icon'"
          [Size]="'medium'"
          [IconType]="group.icon"
          background_color="{{ group.cls }}"></app-icon-container>
        <label class="h3-bold primary-100">{{ group.caption }}</label>
        <ng-container *ngIf="group.count">
          <div class="flex-grow-1"></div>
          <app-counter [count]="group.count.toString()" size="large"></app-counter>
          <app-action-btn
            class="reset-btn"
            [active]="false"
            type="button"
            IconType="cancel"
            (click)="clearFilter()"></app-action-btn>
        </ng-container>
      </div>
      <dropdown-single-piece
        *ngFor="let filter of group.entries || []"
        class="w-100 cursor-pointer"
        label="{{ filter.label }}"
        [color]="'default'"
        [type]="'reg'"
        [state]="'default'"
        [icon]="true"
        iconType="{{ filter.icon }}"
        [dragDrop]="false"
        [toggle]="false"
        [more]="false"
        [radio]="false"
        (click)="addFilter(filter)"></dropdown-single-piece>
    </div>
  </div>
</ng-template>
