<div class="d-flex spacing-4 w-100 align-items-center justify-content-between">
  <div class="d-flex spacing-4 align-items-center">
    <ng-container *ngFor="let record of records">
      <app-filter-entry-preview
        *ngIf="!record.entry.hidden"
        [record]="record"
        (onRemove)="onRecordRemove($event)"
        (click)="selectRecord(record, trigger)"
        cdkOverlayOrigin
        #trigger="cdkOverlayOrigin">
      </app-filter-entry-preview>
    </ng-container>
  </div>
  @if (records && records.length > 0) {
    <app-link-btn
      class="text-nowrap"
      label="Clear all"
      [active]="false"
      size="medium"
      IconType="none"
      [disabled]="false"
      (custom_click)="clearFilter()"></app-link-btn>
  }
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="triggerOrigin!"
  [cdkConnectedOverlayOffsetY]="12"
  [cdkConnectedOverlayOpen]="editorOpen"
  (overlayOutsideClick)="closeEditor()">
  <div class="filter-editor">
    <app-filter-entry-editor
      (onApply)="onApply($event)"
      [record]="selectedRecord"></app-filter-entry-editor>
  </div>
</ng-template>
