import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterRecord, Operator, OperatorAvailability } from '@services/core-services/filters';
import { FilterEntryEditorContainerComponent } from '@enginuity/core/organisms/filtering/filter-entry-editor-container/filter-entry-editor-container.component';
import { FilterOperatorSelectComponent } from '@enginuity/core/organisms/filtering/filter-operator-select/filter-operator-select.component';
import { ButtonsComponent } from '@enginuity/core/molecules/buttons/buttons.component';

@Component({
  selector: 'app-filter-entry-editor',
  standalone: true,
  imports: [
    CommonModule,
    FilterEntryEditorContainerComponent,
    FilterOperatorSelectComponent,
    ButtonsComponent,
  ],
  templateUrl: './filter-entry-editor.component.html',
  styleUrl: './filter-entry-editor.component.scss',
})
export class FilterEntryEditorComponent {
  private _record: FilterRecord | undefined;
  private internalRecord: FilterRecord | undefined;

  get record(): FilterRecord | undefined {
    return this._record;
  }

  @Input() set record(value: FilterRecord | undefined) {
    if (value) {
      const { rule, entry } = value;
      const operator: Operator =
        rule?.operator || OperatorAvailability[entry.type]?.[0] || Operator.Equals;
      this._record = { entry, rule: { ...rule, operator } };
    } else {
      this._record = value;
    }

    this.internalRecord = JSON.parse(JSON.stringify(this._record));
  }
  @Output() onApply: EventEmitter<FilterRecord> = new EventEmitter<FilterRecord>();

  onOperatorChange(operator: any) {
    const record = this._record!;
    const rule = record.rule;
    this._record = { ...record, rule: { ...rule, operator } };
    this.internalRecord = JSON.parse(JSON.stringify(this._record));
  }

  onFilterValueChange(value: any) {
    const record = this.internalRecord!;
    const rule = record.rule;
    this.internalRecord = { ...record, rule: { ...rule, value } };
  }

  onFilterApply() {
    setTimeout(() => {
      this.record = this.internalRecord;
      this.onApply.emit(this.record);
    }, 700);
  }
}
